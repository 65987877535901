.banner-container{
    width: 100%;
    height:350px;
    padding:0;
    position: relative;
}
.banner-inner{
    background-color: rgba(0, 0, 0, 0.2);
    height: 350px;
    position:absolute;
    top: 0;
    width: 100%;
    display:flex;
    justify-content:center;
    align-items: center;
}
.detail-container{
    width: 59.3%;
    height: 350px;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    padding: 0;
}
.detail-container-footer{
    position: absolute;
    height: 150px;
    bottom: 0;
    left: 0;
  
}
.event-tag{
    width: 60px;
    height: 22px;
    background-color: transparent;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFFFF;
    margin-top: 10px;
}
.event-tag-text{
    font-size: 12px;
    color: #FFFFFF;
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: normal;
}
.detail-container-body{
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
  display: inline-block;
    
}
.event-time-box {
    height: 30px;
   display: flex;
    align-items: center;
}
.event-time-text{
    font-size: 14px;
    color: rgba(209,213,219);
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: normal;
}
.location-box{
    width: 100%;
    height: 40px;
   display: flex;
    align-items: center;
}
.location-text{
    color: #FFFFFF;
    font-family: "Roboto", serif;
    font-weight: 500;
    font-size: 1.125rem;
    margin-left: 10px;
}
.home-share-box{
    height: 70px;
    background-color: transparent;
    width: 59.3%;
   

 }
 .home-share-box-share-content{
    height: 55px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    width: 99%;
    padding: 6px;
    display: flex;
    align-items: center;
   cursor: pointer;
   justify-content: space-between;
 }
.home-share-box-share-btn{
     height: 55px;
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    margin-left: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.share-link{
    font-size: 12px;
    display: block;
    margin-left: 10px;
      color: rgba(107,114,128);
    font-family: "Roboto", serif;
}
.copy-message{
    font-size: 14px;
    display: block;
    margin-left: 10px;
    color: #000000;
    font-family: "Roboto", serif;
}
.link-icon{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-selection-container {
    width: 80%;
    height: 50px;

}
.home-selection-box{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center; 
    align-items: center;
}
.home-selection-left{
    width: 40%;
    height: 45px;
    border:1px solid #ff9b05;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.home-selection-right {
     width: 40%;
    height: 45px;
    border:1px solid #ff9b05;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;
     display: flex;
    justify-content: center;
    align-items: center;
}
.event-selection-text {
       font-size: 14px;
    display: block;
    margin-left: 10px;
    color: #000000;
    font-family: "Roboto", serif;
}
.event-detail-container{
    width: 80% !important;
    min-height: 400px;
    margin-top: 20px;
}
.event-detail{
   
    min-height: 250px;
   margin-top: 20px;
   
}

.event-attendees-container{
    width: 78.6% !important;
    min-height: 400px;
    margin-top: 20px;
}
.event-attendees{
    width: 78.6% !important;
    min-height: 400px;
   margin-top: 20px;
}
.event-attendees-header{
    width: 100%;
    height: 40px;
    background-color: transparent;
    display: flex;
    align-items: center;

}
.attendees-item{
    height: 60px;
    border-bottom: 0.5px solid rgba(209,213,219);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.attendees-item-info{
    height: 50px;
    display: flex;
    align-items: center;
}
.attendees-item-name{
    font-size: .875rem;
    font-weight: 500;
    font-family: "Roboto", serif;
    color: rgba(17, 24, 39);
    white-space: nowrap;
    margin-left: 10px;
}
.event-about{
    
    min-height: 150px;
    margin-top: 10px;
    
}
.event_about-header{
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
}
.event-about-box{
    width: 100%;
    min-height: 50px;
  
}
.event-detail-box{
    height: 350px;
    width: 100%;

}
.event-similarity{
    height: 350px;
    width: 100%;
   
}
.event-detail-box-inner{
    width: 98%;
    margin-left: 1%;
    height: 350px;
  
}
.event-similarity-inner{
    width: 98%;
    margin-left: 1%;
    height: 350px;
   
}
.event-detail-box-item{
    width: 100%;
    height: 45px;
    border-bottom: 0.5px solid rgba(209,213,219);
    display: flex;
    align-items: center;
}
.event-detail-my-status{
     font-size: .875rem;
    font-weight: 500;
    font-family: "Roboto", serif;
    color: #008000;
    white-space: nowrap;
    margin-left: 10px;
}
.event-similarity-inner-header{
    width: 100%;
    height: 45px;
   display: flex;
    align-items: center;
}