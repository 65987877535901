.footer-container{
    height: 500px;
    background-color: black;
    margin-top: 80px;
}
.footer-logo-holder{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-logo{
    width: 35%;
}
.footer-content{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.footer-app-links{
  
    height: 70px;
    display: flex;
    margin-left: 10%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
 
}
.app-links{
   
    height: 40px;
    cursor: pointer;
   
}
.footer-links{
   
    
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.horizontal-list {
  list-style-type:none;
  display: flex;
 /* Adds spacing between items */
}
.footer-link-li{
    display: flex;
   
}

.footer-link-li a{
    font-family: "Roboto", serif;
    color: #FFFFFF;
    text-decoration: none;
}