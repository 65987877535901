.register-container{
    width: 100%;
    height: 100vh;
    background-color: #ff9b05;
}           
.register-box{
    height: 100vh;
   padding: 0;
}
.register-box-header{
    width: 100%;
    height: 90px;
  
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
}                                                              
.close-btn{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.footer-input-form{
    width: 94%;
    min-height: 80px;
    margin-left: 3%;
}
.input-message{
    font-family: "Roboto", serif;
    color: #FFFFFF;
    font-size: 14px;
    display: block;
}